import { ApprenticeProfile as User } from '@qureos/types'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { user } from 'src/atoms'
import { UserRole } from 'src/types/shared/graphql'

const ZendeskProvider: React.FC = () => {
  const [profile] = useRecoilState<User>(user)
  const [showZendeskScript, setShowZendeskScript] = useState<boolean>(false)

  useEffect(() => {
    setShowZendeskScript(
      !profile || (profile && profile.role === UserRole.Student)
    )
  }, [profile])

  useEffect(() => {
    let zendeskScript: HTMLScriptElement | null = null

    if (showZendeskScript) {
      zendeskScript = document.createElement('script')
      zendeskScript.src =
        'https://static.zdassets.com/ekr/snippet.js?key=ff58c4de-f63e-4562-b8e5-2d7fd550f992'
      zendeskScript.id = 'ze-snippet'
      zendeskScript.async = true
      document.head.appendChild(zendeskScript)
    }

    // removing the script is not the solution bec it's not working right.
    // we had to dynamically set visibility of the widget to handle this.
    const launcherDiv = document.getElementById('launcher')

    if (launcherDiv) {
      // Crawl up two parent divs
      const parentDiv = launcherDiv.parentElement?.parentElement

      // Remove the entire parent element
      if (parentDiv) {
        parentDiv.style.visibility = showZendeskScript ? 'visible' : 'hidden'
      }
    }

    return () => {
      if (zendeskScript) {
        document.head.removeChild(zendeskScript)
      }
    }
  }, [showZendeskScript])

  return null
}

export default ZendeskProvider
