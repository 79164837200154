/* eslint-disable no-async-promise-executor */
import { getLoginToken, isLoggedIn, loginRedirect, notify } from '@/utils/lib'
import { parseJwt } from '@/utils/static-helpers/jwt'
import { ApprenticeProfile, UserRole } from '@qureos/types'
import Cookies from 'js-cookie'
import { assign, isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useRecoilState } from 'recoil'
import { user as userAtom } from 'src/atoms'
import {
  updateProfile as updateProfileAtom,
  userTzOffset
} from 'src/atoms/user'
import { useLogout } from 'src/hooks'
import { getProfile } from 'src/services/profile/user'

export interface ProfileProviderProps {
  onError?: () => void
  onCompleted?: () => void
  children?: ReactNode
}

export const ProfileProvider: FC<ProfileProviderProps> = ({
  onError,
  onCompleted,
  children
}) => {
  const router = useRouter()
  const [, setUser] = useRecoilState<ApprenticeProfile>(userAtom)
  const tzOffset = useRecoilValue<number>(userTzOffset)
  const [update, setUpdate] = useRecoilState<boolean>(updateProfileAtom)
  const logout = useLogout()

  const onboardingWhitelistedPages = [
    /\/waitlist\/.+/g,
    /\/enrollment\/.+\/payment.*$/g,
    /\/transaction\/.+\/success.*$/g,
    /\/enrollment\/.+\/scholarship.*$/g,
    /\/subscription\/choose-plan.*$/g,
    /\/learning-hub\/.+\/choose-plan.*$/g,
    /\/payment\/.+\/success.*$/g,
    /\/learning-path\/.+/g,
    /\/apprentice-onboarding/g,
    /\/profile\/.+/g
  ]

  const handleRootRedirect = () => {
    const rootPages = [
      '/login',
      '/signup',
      '/corporate/login',
      '/corporate/signup',
      '/mentor/signin',
      '/mentor/signup'
    ]

    const originalPath = window?.location?.pathname
    const path = originalPath.split('?')[0]
    const isRootPath =
      rootPages.some(page => path.startsWith(page)) || path === '/'

    if (isRootPath) return

    if (!isEmpty(originalPath) && originalPath !== '/') {
      const redirectUrl = originalPath
      loginRedirect('set', redirectUrl)
    }

    const token = getLoginToken()
    let newPath = '/'

    if (token) {
      const user = parseJwt(token)

      switch (UserRole[user?.role]) {
        case UserRole.ADMIN:
        case UserRole.STUDENT:
          newPath = '/'
          break
        case UserRole.MENTOR:
          newPath = '/mentor/signin'
          break
        case UserRole.PROFESSIONAL:
          newPath = '/corporate/login'
          break
      }
      logout()
    }
    router.replace(newPath)
  }

  const setUserData = async (data: ApprenticeProfile) => {
    setUser(data)
    try {
      const redirectUrl = loginRedirect('get')
      if ((data.role as unknown as UserRole) === UserRole.STUDENT) {
        const path = router.asPath.split('?')[0]
        if (data.birthdate) {
          if (data?.signedUpForJob) return
          const matchFound = onboardingWhitelistedPages.some(page =>
            page.test(path)
          )
          if (matchFound) return
          if (Cookies.get('departmentFilter')) {
            router.push('/jobs')
            return
          }
          if (router.asPath.includes('redirectUrl')) {
            const redirectUrl = router.query?.redirectUrl as string
            router.push(redirectUrl)
            return
          }
          const onboardingStep = data?.meta?.onboardingStep
          if (data?.meta && (!onboardingStep || onboardingStep < 1)) {
            const redirectUrl = router.query?.redirectUrl as string
            router.push(`/apprentice-onboarding?redirectUrl=${redirectUrl}`)
            return
          }
        } else {
          if (router.isReady && !path.includes('verify-birthday')) {
            if (redirectUrl !== undefined) {
              router.push(`/verify-birthday?redirectUrl=${redirectUrl}`)
            } else {
              router.push(`/verify-birthday?redirectUrl=${path}`)
            }
          }
        }
      } else {
        if (redirectUrl !== undefined) {
          loginRedirect('remove')
          router.replace(redirectUrl)
        }
      }
    } catch (e) {
      console.error('profile error', e)
      notify({
        message: 'Your session had expired, please sign in again.',
        type: 'error'
      })
    }
  }

  const populate = (data: ApprenticeProfile) => {
    setUserData(data)
  }

  const getUserProfiles: () => Promise<ApprenticeProfile> = async () => {
    return new Promise(async resolve => {
      try {
        let data: ApprenticeProfile
        const v3Profile = await getProfile(tzOffset)
        const role = v3Profile.data.role
        if (![UserRole.ENTERPRISE, UserRole.STUDENT].includes(role)) {
          data = v3Profile.data
        } else {
          data = assign(v3Profile.data)
        }
        resolve(data)
        onCompleted && onCompleted()
      } catch (err) {
        if (onError) {
          onError()
        } else {
          handleRootRedirect()
          notify({
            message: 'Your session had expired, please sign in again.',
            type: 'error'
          })
        }
      }
    })
  }

  useEffect(() => {
    if (router.isReady) {
      if (isLoggedIn()) {
        getUserProfiles().then(data => populate(data))
      } else {
        setTimeout(() => {
          handleRootRedirect()
        }, 500)
        setUser(null)
        onError && onError()
      }
    }
  }, [router.isReady])

  // Profile live update watcher
  useEffect(() => {
    const timer = setTimeout(() => {
      if (update && router.isReady) {
        getUserProfiles().then(data => {
          populate(data)
        })

        setTimeout(() => {
          setUpdate(false)
        }, 1000)
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [update, router.isReady])
  // end profile watcher

  return <>{children}</>
}
