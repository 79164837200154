import { BasicUserProfile } from 'src/hooks/useBasicUserProfile'
import apiClient from 'src/services/base'
import token from '@/utils/static-helpers/token'
import { UserDTO } from '@qureos/types'

interface CachedResponse<T> {
  data: T
  timestamp: number
}

const basicProfileCache: Record<string, CachedResponse<BasicUserProfile>> = {}
// Invalidate cache every x minutes
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000

/**
 * Check if the cache is expired
 * @param timestamp - The timestamp of the cache
 * @returns - True if the cache is expired, false otherwise
 */
const isCacheExpired = (timestamp: number): boolean =>
  Date.now() - timestamp > CACHE_EXPIRATION_TIME

// Invalidate cache every x minutes
setInterval(() => {
  for (const key in basicProfileCache) {
    if (isCacheExpired(basicProfileCache[key].timestamp)) {
      delete basicProfileCache[key]
    }
  }
}, CACHE_EXPIRATION_TIME)

/**
 * Get the basic profile of a user (cached)
 * @param id - The id of the user
 * @returns - The basic profile of the user
 */
export const getBasicProfile = (id: string): Promise<BasicUserProfile> => {
  const cached = basicProfileCache[id]
  if (cached && !isCacheExpired(cached.timestamp))
    return Promise.resolve(cached.data)

  return apiClient.get(`/profile/basic/${id}`).then(resp => {
    const response = resp.data

    if (response) {
      basicProfileCache[id] = {
        data: response,
        timestamp: Date.now()
      }
    }

    return response
  })
}

/**
 * Get the profile of a user
 * @param tzOffset - The timezone offset of the user
 * @returns - The profile of the user
 */
export const getProfile = (tzOffset: number): any => {
  return apiClient.get(
    `/profile?includeCertificates=true&tzOffset=${tzOffset.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${token.get()}`
      }
    }
  )
}

/**
 * Send a delete confirmation request
 * @returns - The response from the server
 */
export const sendDeleteConfirmation = (): any => {
  return apiClient.post('/profile/delete-confirmation', {
    headers: {
      Authorization: `Bearer ${token.get()}`
    }
  })
}

/**
 * Delete the profile of a user
 * @param password - The password of the user
 * @param reason - The reason for deleting the profile
 * @param verificationCode - The verification code for deleting the profile
 * @returns - The response from the server
 */
export const deleteProfile = (
  password: string,
  reason: string,
  verificationCode: string
): any => {
  return apiClient.delete('/profile', {
    headers: {
      Authorization: `Bearer ${token.get()}`
    },
    data: {
      password,
      reason,
      verificationCode
    }
  })
}

/**
 * Get a user by their email
 * @param email - The email of the user
 * @returns - The user UserDTO
 */
export const getUserByEmail = (email: string): Promise<UserDTO> => {
  return apiClient.get(`/users/email?email=${email}`).then(resp => resp.data)
}
