import { user } from 'src/atoms'
import { useSetRecoilState } from 'recoil'
import { logout as logoutUser } from '@/utils/lib'
import { logoutSegment } from '@/utils/hotjar'

export const useLogout: () => VoidFunction = () => {
  const setUser = useSetRecoilState(user)

  const logout = () => {
    logoutUser()
    logoutSegment()
    setUser(null)
  }

  return logout
}
