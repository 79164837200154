const tokenKey = 'b2c_token'

class Token {
  set(token: string): this {
    localStorage.setItem(tokenKey, token)
    return this
  }
  get(): string {
    return localStorage ? String(localStorage.getItem(tokenKey)) : '' // in case of an iframe, we don't have localStorage access by default
  }
}

export default new Token()
